import React from "react";
import styled from "styled-components";
import { breakpoints, spacers } from "../styles/__variables";

interface ParagraphProps {
  color?: string;
  spaced?: boolean;
}

const P = styled.p<ParagraphProps>`
  color: ${(props) => (props.color === undefined ? "#fff" : props.color)};
  margin-bottom: ${(props) =>
    props.spaced ? `${spacers.mobile}px` : "inherit"};
  font-family: "Montserrat Light";
  font-size: 16px;
  line-height: 24px;
  max-width: 800px;

  b {
    font-family: "Montserrat Bold";
  }

  em {
    font-weight: 300;
    font-family: "Montserrat Light Italic";
  }

  @media screen AND (min-width: ${`${breakpoints.md}px`}) {
    font-size: 20px;
    line-height: 29px;
    margin-bottom: ${(props) =>
      props.spaced ? `${spacers.tablet}px` : "inherit"};
  }

  @media screen AND (min-width: ${`${breakpoints.xxl}px`}) {
    font-size: 24px;
    line-height: 34px;
  }
`;

const Paragraph: React.FC<ParagraphProps> = ({ children, color, spaced }) => {
  return (
    <P color={color} spaced={spaced}>
      {children}
    </P>
  );
};

export default Paragraph;
