import React from "react";
import styled from "styled-components";

import { breakpoints } from "../styles/__variables";

const LogoDiv = styled.div`
  div {
    display: block;
    height: auto;
    width: 280px;
    height: 176px;
    margin: 0 auto;
    background-image: url("/assets/images/logo-mobile.svg");
    background-size: 100%;
    background-repeat: no-repeat;
  }

  @media screen AND (min-width: ${`${breakpoints.xl}px`}) {
    div {
      max-width: 313px;
      max-height: 231px;
      margin: 0;
      background-image: url("/assets/images/logo_negativ.svg");
      background-size: 100%;
    }
    &.navLogo {
      > div {
        max-width: 313px;
        max-height: 231px;
        margin: 0 auto;

        @media screen AND (min-height: 900px) {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  @media screen AND (min-width: ${`${breakpoints.xxl}px`}) {
    div {
      width: 404px;
      height: 298px;
      margin: 0;
      background-image: url("/assets/images/logo_negativ.svg");
      background-size: 100%;
    }

    &.navLogo {
      > div {
        max-width: 404px;
        max-height: 298px;
        margin: 0 auto;
      }
    }
  }
`;

const Logo: React.FC = ({ navLogo }) => {
  return (
    <LogoDiv className={navLogo ? "navLogo" : ""}>
      <div />
    </LogoDiv>
  );
};

export default Logo;
