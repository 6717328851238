interface Links {
  link: string;
  title: string;
}

interface WarumItems {
  item: string;
  index: number;
}

interface TestimonialItems {
  item: string;
  author: string;
  job: string;
  index: number;
}

interface ListItem {
  item: string;
  index: number;
}

interface Projekt {
  item: string;
  index: number;
}

export const links: Links[] = [
  {
    link: "/",
    title: "start",
  },
  {
    link: "angebot",
    title: "angebot",
  },
  {
    link: "portrait",
    title: "portrait",
  },
  {
    link: "warum",
    title: "warum",
  },
  {
    link: "projekte",
    title: "projekte",
  },
  {
    link: "kontakt",
    title: "kontakt",
  },
];

export const warumItems: WarumItems[] = [
  {
    item:
      "Detaillierte Auftragsklärung ist für mich das A und O – damit Sie genau das erhalten, was Sie brauchen.",
    index: 0,
  },
  {
    item: "Meine Lösungen sind pragmatisch und effizient.",
    index: 1,
  },
  {
    item:
      "Meine Kompetenzen setze ich für Sie ein: Führungserfahrung auf Geschäftsleitungs-Ebene, HR-Erfahrung und -Weiterbildungen, langjährige Erfahrung als Dozentin und Assessorin.",
    index: 2,
  },
  {
    item:
      "Sie haben immer die gleiche Ansprechperson - falls nötig und gewünscht, binde ich auch mein Netzwerk ein.",
    index: 3,
  },
  {
    item:
      "Als kleine Firma hat Rundum HR keinen (kostentreibenden) administrativen Überbau.",
    index: 4,
  },
];

export const projekte: Projekt[] = [
  {
    item:
      "Einführen des Labels Friendly Work Space zur Qualitätssicherung des betrieblichen Gesundheitsmanagements",
    index: 0,
  },
  {
    item:
      "Erstellen eines Rekrutierungs-Prozesses inkl. Hilfsmittel wie Anforderungsprofile, Interviewbögen, Arbeitsproben, Referenzbögen und eines Leitfadens für Führungspersonen",
    index: 1,
  },
  {
    item:
      "Aufbau eines Personalentwicklungs-Prozesses inkl. Instrumente wie Personalentwicklungskonzept und Wegleitungen",
    index: 2,
  },
  {
    item:
      "Optimierung eines Beurteilungssystems inkl. Hilfsmittel wie Wegleitungen und Schulungen",
    index: 3,
  },
  {
    item: "Einführung eines Austrittsmonitorings",
    index: 4,
  },
  {
    item: "Analyse der HR-Dienstleistungen von Unternehmen inkl. Handlungsempfehlungen",
    index: 5,
  },
  {
    item:
      "Erarbeitung von Führungsgrundsätzen inkl. griffigen Instrumenten, damit diese auch im Alltag gelebt werden",
    index: 6,
  },
  {
    item:
      "Dozententätigkeit an höheren Fachschulen und Vorbereitungskursen auf Berufsprüfungen zu den Themen HR, Führung, Kommunikation",
    index: 7,
  },
];

export const testimonialItems: TestimonialItems[] = [
  {
    item:
      "Frau Rechsteiner hatte einen sehr guten Zugang zu den Studierenden und verstand es, sie zu motivieren. Den Unterricht gestaltete sie abwechslungsreich sowie interessant und passte ihn mit viel Geschick den jeweiligen individuellen Fähigkeiten an.",
    author: "Sarah Thomann",
    job: "Standortleiterin HSO Bern",
    index: 0,
  },
  {
    item:
      "Meine Erwartungen an Andrea Rechsteiner waren hoch. Sie hat sie nicht nur erfüllt, sondern bei weitem übertroffen. Mit ihrer Professionalität und ihrem Arbeitstempo hat sie mich immer wieder aufs Neue beeindruckt. So erarbeitete sie mit ihrem Team zuhanden der Geschäftsleitung die HR-Strategiepapiere und setzte sie danach gekonnt und mit der richtigen Mischung aus Beharrlichkeit und Einfühlungsvermögen um. Heute arbeitet die IV-Stelle Kanton Bern mit klar definierten HR-Prozessen und -Instrumenten.",
    author: "Dieter Widmer",
    job: "ehemaliger Direktor IV-Stelle Kanton Bern",
    index: 1,
  },
  {
    item:
      "In Führungs-Coachings zeigt sich Frau Rechsteiner als wertschätzende und professionelle Gesprächspartnerin, die Führungskräfte in ihrer persönlichen Weiterentwicklung kompetent begleitet und ihnen hilft, Sicherheit in schwierigen Führungssituationen zu gewinnen.",
    author: "Ursula Gut",
    job: "Facilitator GUT zusammenleben GmbH",
    index: 2,
  },
  {
    item:
      "Frau Rechsteiner stellt ihre Beobachtungsgabe, ihre Sorgfalt und ihre Ausdrucksfähigkeit mit differenzierten, ausgewogenen und klar nachvollziehbaren Berichten zu anspruchsvollen Assessments immer wieder unter Beweis.",
    author: "Ursula Gut",
    job: "Facilitator GUT zusammenleben GmbH",
    index: 3,
  },
  {
    item:
      "Aufgrund ihrer hervorragenden fachlichen Kenntnisse und Kompetenzen wurde Andrea Rechsteiner von uns beauftragt, den HR-Teil im Lehrgang Wirtschaftsinformatiker/in HF zu entwickeln. Die Zusammenarbeit mit ihr – angefangen von der Auftragsannahme, der Konzeptionierung und des pünktlich abgelieferten Resultats – war höchst professionell und sehr angenehm. Bis heute profitieren die Studierenden von einem methodisch-didaktisch einwandfreien und äusserst gut strukturierten HR-Teil in ihrer Weiterbildung.",
    author: "Dominique Meldau",
    job: "ehemalige:r Produktmanager:in WISS",
    index: 4,
  },
  {
    item:
      "Andrea Rechsteiner hat den Visions-, Leitbild- und Strategieprozess der Blindenschule Zollikofen zielführend gestaltet, Interviews geführt, eine Situationsanalyse erstellt, Workshops vorbereitet, organisiert und mitmoderiert. Dieses Projekt geht weit über ihren Aufgabenbereich als Leiterin HR hinaus. Sie hat mit ihrem Einsatz wesentlich dazu beigetragen, dass wir den Prozess erfolgreich gestalten konnten. ",
    author: "Carmelina Castellino",
    job: "Direktorin Blindenschule Zollikofen",
    index: 5,
  },
  {
    item:
      "Die Workshops zur Standortbestimmung waren für das Aufgleisen eines Betrieblichen Gesundheitsmanagements in unserer Organisation sehr aufschlussreich und von zentraler Bedeutung. Andrea Rechsteiner gelang es dank ihrer klaren Kommunikation und wohlwollenden Art sehr gut, die Gruppe abzuholen und den Fokus der Diskussion immer wieder auf das Wesentliche zu leiten.",
    author: "Karin Hofmann",
    job: "Geschäftsleiterin Verein Wohnenbern",
    index: 6,
  },
];

export const entwickelnListe: ListItem[] = [
  {
    item: "Konzeptentwicklung",
    index: 0,
  },
  {
    item: "Coachings: Schwerpunkte «Neu in der Führungsrolle» und «PERMA-Lead» (positive Leadership)",
    index: 1,
  },
  {
    item: "Teamcoachings",
    index: 2,
  },
];

export const beratenListe: ListItem[] = [
  {
    item: "Beratung Betriebliches Gesundheitsmanagement (BGM)",
    index: 0,
  },
  {
    item: "Optimierung HR-Prozesse / -Instrumente",
    index: 0,
  },
];

// export const anpackenListe: ListItem[] = [
//   {
//     item: "Ad interim",
//     index: 0,
//   },
//   {
//     item: "Outsourcing",
//     index: 0,
//   },
// ];

export const schreibenListe: ListItem[] = [
  {
    item: "Newsletter",
    index: 0,
  },
  {
    item: "Leitfäden und Wegleitungen",
    index: 0,
  },
  {
    item: "Arbeitszeugnisse",
    index: 0,
  },
];

export const meinePapiereListe: ListItem[] = [
  {
    item: "Executive MBA HR",
    index: 0,
  },
  {
    item: "Leiterin HR mit eidg. Diplom",
    index: 1,
  },
  {
    item: "HR-Fachfrau mit eidg. Fachausweis",
    index: 2,
  },
  {
    item: "Betriebliche Mentorin mit eidg. Fachausweis",
    index: 3,
  },
  {
    item: "Dipl. Coach SCA",
    index: 4,
  },
  {
    item: "Coach Perma-Lead",
    index: 5,
  },
  {
    item: "Primarlehrerinnen-Patent",
    index: 6,
  },
  {
    item: "Erwachsenenbildung SVEB 1",
    index: 7,
  },
];

export const meineErfahrungListe: ListItem[] = [
  {
    item:
      "Mehr als 20 Jahre Erfahrung im HR (Führungs- und Spezialistinnen-Funktionen) im privaten wie öffentlich-rechtlichen Bereich",
    index: 0,
  },
  {
    item: "Mehr als 15 Jahre Erfahrung als Geschäftsleitungsmitglied",
    index: 1,
  },
  {
    item: "Über 10 Jahre als Expertin für die eidg. Berufsprüfung HR-Fachleute",
    index: 2,
  },
  {
    item:
      "Viele Jahre Erfahrung als Dozentin für die Themen HR, Kommunikation und Führung",
    index: 3,
  },
  {
    item: "Seit rund 6 Jahren als Assessorin und Coach tätig",
    index: 4,
  },
];
