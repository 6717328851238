import * as React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import { spacers, breakpoints } from "../../styles/__variables";
import Wrapper from "../../layouts/Wrapper";
import NavToggle from "./NavToggle";

interface HeaderProps {
  isNavVisible: boolean;
  setIsNavVisible: React.Dispatch<React.SetStateAction<boolean>>;
  navColor: string;
  navColorDesktop: string;
}

const HeaderElem = styled.div`
  z-index: 4;
  position: fixed;
  width: 100%;
  background: white;
  left: 0;
  right: 0;
  top: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media screen AND (min-width: ${`${breakpoints.md}px`}) {
    top: 0;
    right: ${`${spacers.tablet}px`};
  }

  @media screen AND (min-width: ${`${breakpoints.xl}px`}) {
    left: auto;
    width: auto;
    background: none;
    top: ${`${spacers.tablet}px`};
    right: ${`${spacers.desktop}px`};
  }

  div:nth-of-type(2) {
    > div {
      top: 3px;
      right: ${`${spacers.mobile}px`};

      @media screen AND (min-width: ${`${breakpoints.md}px`}) {
        top: 0;
        right: ${`${spacers.tablet}px`};
      }
    }
  }
`;

const LogoDiv = styled.div`
  padding: 7px ${`${spacers.mobile}px`} 7px 0;
  width: 300px;
  @media screen AND (min-width: ${`${breakpoints.xl}px`}) {
    display: none;
  }
`;

// background-color from styles?

const Header: React.FC<HeaderProps> = ({
  isNavVisible,
  setIsNavVisible,
  navColor,
  navColorDesktop,
}) => {
  return (
    <HeaderElem>
      <Wrapper>
        <LogoDiv>
          <Link to="/">
            {/* filesystem plugin */}
            <img src="/assets/images/logo-horizontal.svg" />
          </Link>
        </LogoDiv>
        <div>
          {/* nav toggle */}
          <NavToggle
            style={isNavVisible ? { display: "none" } : { display: "inherit" }}
            isNavVisible={isNavVisible}
            setIsNavVisible={setIsNavVisible}
            navColor={navColor}
            navColorDesktop={navColorDesktop}
          />
        </div>
      </Wrapper>
    </HeaderElem>
  );
};

export default Header;
