import React from "react";
import styled from "styled-components";
import { breakpoints, spacers } from "../styles/__variables";

interface TitleColor {
  color: string;
  topspaced?: boolean;
}

const Heading = styled.h1<TitleColor>`
  color: ${(props) => props.color};
  font-family: "Montserrat Light";
  font-size: 45px;
  line-height: 55px;
  margin-bottom: ${`${spacers.mobile}px`};
  margin-top: ${(props) =>
    props.topspaced ? `${spacers.mobile * 1.5}px` : "inherit"};

  @media screen AND (min-width: ${`${breakpoints.md}px`}) {
    margin-bottom: ${`${spacers.tablet}px`};
    font-size: 54px;
    line-height: 65px;

    margin-top: ${(props) =>
      props.topspaced ? `${spacers.tablet * 1}px` : "inherit"};
  }

  @media screen AND (min-width: ${`${breakpoints.xl}px`}) {
    font-size: 64px;
    line-height: 76px;
    margin-bottom: ${`${spacers.desktop}px`};
    margin-top: ${(props) =>
      props.topspaced ? `${spacers.desktop}px` : "inherit"};

    @media screen AND (min-height: 850px) {
      margin-top: ${(props) =>
        props.topspaced ? `${spacers.desktop * 2}px` : "inherit"};
    }
  }

  @media screen AND (min-width: ${`${breakpoints.xxl}px`}) {
    font-size: 72px;
    line-height: 88px;
  }
`;

const Title: React.FC<TitleColor> = ({ children, color, topspaced }) => {
  return (
    <Heading topspaced={topspaced} color={color}>
      {children}
    </Heading>
  );
};

export default Title;
