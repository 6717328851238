/* eslint-disable max-len */

export const colors = {
  lightGreen: {
    base: "#D0EF8F",
    background: "#F3FBE3",
    text: "#212617",
  },
  lightBrown: {
    base: "#A5987F",
    background: "#F1EBDF",
    text: "#26231D",
  },
  yellowGreen: {
    base: "#999500",
    background: "#F2F1DE",
    text: "#262500",
  },
  grey: {
    base: "#63656A",
    background: "#E1E2E2",
    text: "#242426",
  },
  blue: {
    base: "#498285",
    background: "#D1E0E0",
    text: "#152526",
  },
  green: {
    base: "#91A663",
    background: "#E3E8D7",
    text: "#212617",
  },
  white: "#fff",
  black: "#000",

  // Missing: BG Brown
};

export const fonts = {
  sansSerif:
    '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", Arial, sans-serif',
  serif: 'Georgia, "Times New Roman", Times, serif',
  monospace:
    'Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace, monospace',
};

export const breakpoints = {
  xs: 0,
  sm: 560,
  md: 768,
  lg: 1024,
  xl: 1280,
  xxl: 1920,
};

export const widths = {
  md: 720,
  lg: 960,
  xl: 1140,
};

export const spacers = {
  mobile: 16,
  tablet: 32,
  desktop: 64,
};

export const heights = {
  footer: {
    mobile: 100,
  },
  nav: {
    mobile: 54,
  },
};

// export const dimensions = {
//   fontSize: {
//     regular: 16,
//     large: 18,
//   },
//   headingSizes: {
//     h1: 2.441,
//     h2: 1.953,
//     h3: 1.563,
//     h4: 1.25,
//   },
//   lineHeight: {
//     regular: 1.45,
//     heading: 1.2,
//   },
//   containerPadding: 1.5,
// };

// export const heights = {
//   header: 60,
// };
