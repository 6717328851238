import * as React from "react";
import styled from "styled-components";
import { spacers, breakpoints } from "../styles/__variables";

// variables file import for widths
// import
const WrapperDiv = styled.div`
  padding: 0 ${`${spacers.mobile}px`};
  width: 100%;
  max-width: 1786px;
  margin: 0 auto;
  position: relative;
  height: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;

  @media screen and (min-width: ${`${breakpoints.md}px`}) {
    padding: 0 ${`${spacers.tablet}px`};
  }

  @media screen and (min-width: ${`${breakpoints.xl}px`}) {
    padding: 0 ${`${spacers.desktop}px`};
  }
`;

const Wrapper: React.FC = ({ children }) => {
  return <WrapperDiv>{children}</WrapperDiv>;
};

export default Wrapper;
