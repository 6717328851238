import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import { colors, breakpoints } from "../../styles/__variables";

interface NavLinkProps {
  link: string;
  title: string;
  navLinkColor: string;
}

const StyledLink = styled.div<NavLinkProps>`
  min-height: 60px;
  height: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;

  a {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-decoration: none;
    height: 100%;

    h4 {
      display: flex;
      flex-direction: row;
      justify-content: center;
      padding: 16px;
      width: 200px;
      margin: 0 auto;
      color: white;
      font-weight: 400;
      font-family: "Montserrat";
      font-size: 24px;
      border-bottom: 2px solid ${colors.grey.base};
    }

    &.active {
      background: ${(props) => props.navLinkColor};
    }

    &.active h4,
    &:hover h4 {
      border-bottom: 2px solid white;
    }

    @media screen and (min-width: ${`${breakpoints.xl}px`}) {
      h4 {
        font-size: 34px;
      }
    }
  }
`;

const NavLink: React.FC<NavLinkProps> = ({
  link,
  title,
  navLinkColor,
}): JSX.Element => {
  return (
    <StyledLink navLinkColor={navLinkColor}>
      <Link activeClassName="active" key={title} to={link}>
        <h4>{title}</h4>
      </Link>
    </StyledLink>
  );
};

export default NavLink;
