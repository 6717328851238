import React from "react";
import styled from "styled-components";
import { breakpoints } from "../../styles/__variables";

interface NavToggleProps {
  isNavVisible: boolean;
  setIsNavVisible: React.Dispatch<React.SetStateAction<boolean>>;
  navColor: string;
  navColorDesktop: string;
}

const ToggleDiv = styled.div<NavToggleProps>`
  position: absolute;
  top: 0px;
  right: 0;
  min-width: initial;
  min-height: initial;
  width: 60px;
  height: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  z-index: 3;
  transition: transform all 3000ms;

  &:hover {
    cursor: pointer;
  }

  > div {
    z-index: 4;
    margin: 5px 0;
    width: 100%;
    height: 5px;
    background: ${(props) => props.navColor};
  }

  &.navVisible {
    > div {
      margin: 0;
    }
    div:first-of-type {
      transform: rotate(45deg) translatey(7px);
    }
    div:nth-of-type(2) {
      visibility: hidden;
    }
    div:last-of-type {
      transform: rotate(-45deg) translatey(-7px);
    }
  }

  @media screen AND (min-width: ${`${breakpoints.xl}px`}) {
    > div {
      background: ${(props) => props.navColorDesktop};
    }
    &.navVisible {
      margin-right: -20px;
    }
  }

  @media screen AND (min-width: ${breakpoints.xxl}px) {
    &.navVisible {
      margin-right: 0px;
    }
  }
`;

const Toggle: React.FC<NavToggleProps> = ({
  setIsNavVisible,
  isNavVisible,
  navColor,
  navColorDesktop,
}) => {
  return (
    <ToggleDiv
      navColor={navColor}
      navColorDesktop={navColorDesktop}
      className={isNavVisible ? "navVisible" : ""}
      onClick={() => {
        setIsNavVisible(!isNavVisible);
      }}
    >
      <div />
      <div />
      <div />
    </ToggleDiv>
  );
};

export default Toggle;
