import * as React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import NavLink from "./NavLink";
import Toggle from "./NavToggle";
import { links } from "../data";
import Logo from "../Logo";

import { colors, spacers, breakpoints } from "../../styles/__variables";

const Nav = styled.div<NavProps>`
  z-index: 5;
  position: fixed;
  height: 100vh;
  min-width: 300px;
  width: 100%;
  right: 0;
  top: 0;
  bottom: 0;
  background: ${colors.grey.base};
  display: flex;
  flex-direction: column;
  margin-right: ${(props) => (props.isNavVisible ? "0px" : "-100%")};
  transition: margin-right 700ms;
  overflow-y: scroll;

  > div {
    min-height: 200px;
  }

  nav {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    z-index: 1;
  }

  @media screen AND (min-width: ${`${breakpoints.lg}px`}) {
    max-width: 34vw;
  }
`;

const NavHead = styled.div`
  position: relative;
  padding: ${`${spacers.mobile}px`};
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 260px;
  width: 100%;

  > a {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 280px;
      height: 176px;
    overflow: hidden;

    > div {
      width: 280px;
      height: 176px;
    }
  }
  > div {
    top: 9px;
    right: 9px;
  }

  @media screen and (min-width: ${`${breakpoints.md}px`}) {
    height: 260px;

    > a {
      height: 231px;
        width: 333px;


      > div {
      height: 231px;
        width: 333px;
      }
    }
  }

  /* @media screen AND (min-width: ${breakpoints.xl}) {
    min-height: 231px;

    > a {
      min-height: 231px;

      > div {
        min-height: 231px;
        width: 313px;
      }
    }
  } */

  @media screen AND (min-width: ${`${breakpoints.xl}px`}) {
    height: 350px;
    > div {
      top: 9px;
      right: ${`${spacers.tablet}px`};
    }

  }

  @media screen AND (min-width: ${`${breakpoints.xxl}px`}) {
    min-height: 260px !important;
    > a {
      width: 333px;
      height: 231px;
      margin-bottom: ${`${spacers.desktop}px`};
      > * {
      width: 333px;
      height: 231px;
    > * {
      width: 333px;
      height: 221px;
    background: url("/assets/images/logo-mobile.svg");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    }
      }
    }

    > div {
      right: ${`${spacers.desktop}px`};
    }


    @media screen and (min-height: 1000px) {

>a {
  >* {
    > *{
    background: url("/assets/images/logo_negativ.svg");

background-repeat: no-repeat;
    background-size: 100% 100%;
    }
  }
}
    }
  }
`;

interface NavProps {
  isNavVisible: boolean;
  setIsNavVisible: React.Dispatch<React.SetStateAction<boolean>>;
  navLinkColor: string;
}

const Navigation: React.FC<NavProps> = ({
  isNavVisible,
  setIsNavVisible,
  navLinkColor,
}) => {
  return (
    <Nav isNavVisible={isNavVisible}>
      <NavHead>
        <Link to="/">
          <Logo navLogo />
        </Link>
        <Toggle
          style={isNavVisible ? { display: "inherit" } : { display: "none" }}
          navColor="white"
          navColorDesktop="white"
          setIsNavVisible={setIsNavVisible}
          isNavVisible={isNavVisible}
        />
      </NavHead>
      <nav>
        {links.map((link) => (
          <NavLink
            navLinkColor={navLinkColor}
            link={link.link}
            title={link.title}
          />
        ))}
      </nav>
    </Nav>
  );
};

export default Navigation;
